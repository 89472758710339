(function() {
  'use strict';

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  function changeFBPagePlugin() {
    var container_width = Number($('.has-feed').width()).toFixed(0);
    if (!isNaN(container_width)) {
      $(".fb-page").attr("data-width", container_width);
    }
    if (typeof FB !== 'undefined') {
      FB.XFBML.parse();
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    let els;

    // Get all "navbar-burger" elements
    els = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger:not(.navbar-burger--collapse)'), 0);

    // Check if there are any navbar burgers
    if (els.length > 0) {

      // Add a click event on each of them
      els.forEach(el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const menu = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          document.body.classList.toggle('is-pushed');
          el.classList.toggle('is-active');
          menu.classList.toggle('is-active');
        });
      });
    }

    bulmaCollapsible.attach();

  });

  $('.button-search').on('click', function() {
    const searchBox = $('.navbar-item--search');
    const navbar = $('.header').find('.navbar');

    if (searchBox.hasClass('is-active')) {
      searchBox.removeClass('is-active');
      navbar.removeClass('has-padding');
    } else {
      searchBox.addClass('is-active');
      navbar.addClass('has-padding');
    }
  });

  $('#search_box_input').keypress(function(e) {
    if (e.which == 13) {
      var searchterm = $.trim($("#search_box_input").val());
      if (searchterm == "") {
        alert('Please enter a search term');
        return false;
      } else {
        window.location = location.origin + '/2014-new-pages/new-search?txtSearch=' + searchterm;
      }
      return false;
    }
  });

  window.addEventListener('resize', debounce(function() {
    changeFBPagePlugin();
  }, 500));

})();


(function() {
  function updateMatchPro360UI() {
    var matchingGiftDiv = $('#chkMG').parent().parent().parent();
    var commentsDiv = $('#txtComments').parent().parent();
    
    if (matchingGiftDiv.length === 0 || commentsDiv.length === 0) {
      return;
    }

    matchingGiftDiv.insertAfter(commentsDiv);
    matchingGiftDiv.prepend(
      `<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bbminiform-drpdowndiv" style="padding-left:0!important;">
        <label class="bbDonationInputLabel">Matching Gift</label>
      </div>`
    )
  }

  function getCompanyName() {
    var continueButton = document.getElementById('btnWizardContinue')

    if(continueButton) {
      continueButton.addEventListener('click', function () {
        var dtdSelectedName = document.querySelector('.dtd-company-selected-name')
        if (dtdSelectedName) {
          var nameStr = dtdSelectedName.textContent
          sessionStorage.setItem('companyName', nameStr)
          console.log(nameStr)
        } else {
          console.log('No company selected')
          sessionStorage.setItem('companyName', '')
        }
      })
    }
  }

  function setThankYou() {
    var headerH2 = document.querySelector('.content h1');
    headerH2.textContent = 'Thank you!';
  }

  const observeConfirmationText = () => {
    const targetElement = document.getElementById('dd-container')
    const observer = new MutationObserver(() => {
      const ddInput = document.getElementById('dd-input')
      if (ddInput) {
        handleDTD()
      }
    })
    const config = { childList: true, subtree: true }
    observer.observe(targetElement, config)
  }

  const observeDTDSearchBox = () => {
    const targetElement = document.querySelector('.dtd-search-box')
    const observer = new MutationObserver(() => {
      const selectedOption = document.querySelector('.dtd-dropdown-is-selected')
      if (selectedOption) {
        selectedOption.click()
        observer.disconnect()
      }
    })
    const config = { childList: true }
    observer.observe(targetElement, config)
  }
   
  const clickDTD = () => {
    const dtdInput = document.querySelector('.dtd-search-input')
    var event = new MouseEvent('input', {
      bubbles: true,
      cancelable: true,
      view: window
    })
    if (!dtdInput) return
    if (dtdInput.value !== '') {
      dtdInput.dispatchEvent(event)
    }
  }

  function handleDTD() {
    console.log('handleDTD')
    var companyName = sessionStorage.getItem('companyName')
    if (!companyName || companyName === '') return

    var companyNameInput = document.querySelector('#dd-input')
    if (!companyNameInput) return
    companyNameInput.value = companyName
    observeConfirmationText()
    observeDTDSearchBox()
    clickDTD()
  }

  var substringToCheck = 'sharing-network-foundation--make-a-contribution-copy'
  var substringToCheck2 = 'Contribute'
  var currentUrl = window.location.href

  if (currentUrl.includes(substringToCheck) || currentUrl.includes(substringToCheck2)) {
    updateMatchPro360UI()
    getCompanyName()

    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('cr') === 'true') {
      console.log('Confirmation page')
      setThankYou()
      observeConfirmationText()
    }
  }
})()